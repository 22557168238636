// import { useEffect, useState } from "react";
// import { Outlet, useLocation } from "react-router-dom";
// import Footer from "../reusable/Footer";
// import Header from "../reusable/Header";
// import HeaderWhite from "../reusable/HeaderWhite";
// import UpperFooter from "../reusable/UpperFooter";
// import langId from '../utils/lang/id';

// export default function HomeLayout({ }) {
//     const [isMobile, setIsMobile] = useState(window.innerWidth <= 360)
//     const [isTablet, setIsTablet] = useState(window.innerWidth <= 768)
//     const [lang, setLang] = useState(langId)
//     const location = useLocation()

//     useEffect(() => {
//         const handleWindowResize = () => {
//             setIsMobile(window.innerWidth <= 360)
//         };

//         window.addEventListener('resize', handleWindowResize);

//         return () => {
//             window.removeEventListener('resize', handleWindowResize);
//         };
//     }, []);

//     useEffect(() => {
//         const handleWindowResize = () => {
//             setIsTablet(window.innerWidth <= 768)
//         };

//         window.addEventListener('resize', handleWindowResize);

//         return () => {
//             window.removeEventListener('resize', handleWindowResize);
//         };
//     }, []);

//     const useHeader = ["/"].includes(location.pathname);
//     const useFooter = ["/contact"].includes(location.pathname);

//     if (isMobile) {
//         return (
//             <div>
//                 {useHeader ? (
//                     <Header isMobile={isMobile} lang={lang} setLang={setLang} />
//                 ) : (
//                     <HeaderWhite isMobile={isMobile} lang={lang} setLang={setLang} />
//                 )}
//                 <div style={{ marginTop: 0 }}>
//                     <Outlet context={[isMobile, setIsMobile, lang, setLang]} />
//                 </div>
//                 {useFooter ? "" : (
//                     <UpperFooter isMobile={isMobile} lang={lang} setLang={setLang} />
//                 )}
//                 <Footer isMobile={isMobile} lang={lang} setLang={setLang} />
//             </div>
//         );
//     } else if (isTablet) {
//         return (
//             <div>
//                 {useHeader ? (
//                     <Header isTablet={isTablet} lang={lang} setLang={setLang} />
//                 ) : (
//                     <HeaderWhite isTablet={isTablet} lang={lang} setLang={setLang} />
//                 )}
//                 <div style={{ marginTop: -28 }}>
//                     <Outlet context={[isTablet, setIsTablet, lang, setLang]} />
//                 </div>
//                 {useFooter ? "" : (
//                     <UpperFooter isTablet={isTablet} lang={lang} setLang={setLang} />
//                 )}
//                 <Footer isTablet={isTablet} lang={lang} setLang={setLang} />
//             </div>
//         );
//     } else {
//         return (
//             <div>
//                 {useHeader ? (
//                     <Header isTablet={isTablet} lang={lang} setLang={setLang} />
//                 ) : (
//                     <HeaderWhite isTablet={isTablet} lang={lang} setLang={setLang} />
//                 )}
//                 <div style={{ marginTop: 0 }}>
//                     <Outlet context={[isTablet, setIsTablet, lang, setLang]} />
//                 </div>
//                 {useFooter ? "" : (
//                     <UpperFooter isTablet={isTablet} lang={lang} setLang={setLang} />
//                 )}
//                 <Footer isTablet={isTablet} lang={lang} setLang={setLang} />
//             </div>
//         );
//     }
// }

import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../reusable/Footer";
import Header from "../reusable/Header";
import HeaderWhite from "../reusable/HeaderWhite";
import UpperFooter from "../reusable/UpperFooter";
import langId from '../utils/lang/id';

export default function HomeLayout() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 420);
    const [isTablet, setIsTablet] = useState(window.innerWidth > 420 && window.innerWidth <= 960);
    const [lang, setLang] = useState(langId);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    const handleWindowResize = () => {
        setIsMobile(window.innerWidth <= 420);
        setIsTablet(window.innerWidth > 420 && window.innerWidth <= 960);
    };

    const handleScroll = () => {
        setIsScrolled(window.scrollY > 800);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const useHeader = ["/"].includes(location.pathname);
    const useFooter = ["/contact"].includes(location.pathname);

    const renderLayout = (isMobile, isTablet) => {
        const headerStyle = {
            transition: 'opacity 0.3s, visibility 0.3s',
            opacity: isScrolled ? 0 : 1,
            // visibility: isScrolled ? 'hidden' : 'visible',
        };

        const headerWhiteStyle = {
            transition: 'opacity 0.3s, visibility 0.3s',
            opacity: isScrolled ? 1 : 0,
            visibility: isScrolled ? 'visible' : 'hidden',
        };

        return (
            <div>
                {useHeader ? (
                    <div style={{ position: 'relative' }}>
                        <div style={headerStyle}>
                            <Header isMobile={isMobile} isTablet={isTablet} lang={lang} setLang={setLang} />
                        </div>
                        <div style={headerWhiteStyle}>
                            <HeaderWhite isMobile={isMobile} isTablet={isTablet} lang={lang} setLang={setLang} />
                        </div>
                    </div>
                ) : (
                    <HeaderWhite isMobile={isMobile} isTablet={isTablet} lang={lang} setLang={setLang} />
                )}
                <div style={{ marginTop: isTablet ? -28 : 0 }}>
                    <Outlet context={[isMobile, isTablet, lang, setLang]} />
                </div>
                {!useFooter && <UpperFooter isMobile={isMobile} isTablet={isTablet} lang={lang} setLang={setLang} />}
                <Footer isMobile={isMobile} isTablet={isTablet} lang={lang} setLang={setLang} />
            </div>
        );
    };

    if (isMobile) {
        return renderLayout(true, false);
    } else if (isTablet) {
        return renderLayout(false, true);
    } else {
        return renderLayout(false, false);
    }
}
