import { useState } from "react";
import { Button, Container, Modal, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import produkKami from "../../assets/product/produk_kami.png";

export default function UpperFooter({ isMobile, isTablet, lang, setLang }) {
    const navigate = useNavigate();
    const [showDialog, setShowDialog] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <>
            <Modal onHide={() => {
                setShowDialog(false);
            }} show={showDialog}>
            </Modal>
            <div style={{ 
                position: 'relative',
                width: "100%", 
                height: isMobile || isTablet ? '252px' : '368px', 
                backgroundImage: `url(${produkKami})`, 
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
                <div className={"d-flex flex-row align-items-center justify-content-center"} style={{
                    color: "white",
                    // backgroundImage: `url(${produkKami})`,
                    backgroundColor: '#0A0A0A7A',
                    
                    width: "100%",
                    height: '100%',
                    position: 'relative',
                    zIndex: 1
                }}>
                    <Container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        <Row className="text-center" md={12}>
                            <div>
                                <h3
                                    style={{
                                        marginBottom: '36px',
                                        fontFamily: 'Bitter',
                                        fontSize: isMobile || isTablet ? '20px' : '36px',
                                        fontWeight: 700,
                                        lineHeight: isMobile || isTablet ? '28px' : '44px',
                                        letterSpacing: '-0.017em',
                                    }}
                                >
                                    {lang.UpperFooter.name}
                                </h3>
                            </div>
                            <div>
                                <Button
                                    onClick={() => {
                                        navigate('/contact');
                                        scrollToTop()
                                    }}
                                    style={{
                                        fontSize: isMobile || isTablet ? '16px' : '18px',
                                        fontWeight: 600,
                                        lineHeight: isMobile || isTablet ? '24px' : '28px',
                                        letterSpacing: '-0.017em',
                                        border: 0,
                                        width: isMobile || isTablet ? '145px' : '160px',
                                        height: '40px',
                                        borderRadius: '24px',
                                        backgroundColor: '#E60000',
                                        cursor: 'pointer',
                                    }}
                                >
                                    {lang.UpperFooter.next}
                                </Button>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}
