import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useOutletContext } from "react-router-dom";
import Swal from 'sweetalert2';
import * as Yup from "yup";
import Contact from '../../models/Contact';
export default function ContactPage() {
	const [isMobile, isTablet, lang, setLang] = useOutletContext()

	const schema = Yup.object().shape({
		full_name: Yup.string().required('Nama harus diisi'),
		email: Yup.string().email().required('Email harus diisi'),
		phone_number: Yup.number().required('Phone Number/Whatsapp harus diisi'),
		message: Yup.string().required('Pesan harus diisi')
	});
	const formik = useFormik({
		initialValues: {
			full_name: "",
			email: "",
			phone_number: "",
			message: ""
		},
		validationSchema: schema,
		onSubmit: async (values, { resetForm }) => {
			try {
				await Contact.sendContact(values)
			} catch (e) {
			}
			resetForm();
			Swal.fire({
				title: 'success!',
				text: 'Thank you, we will provide feedback from your message soon.',
				icon: 'success',
				confirmButtonText: 'Finish'
			})
			// setFormState(values);
		}
	});
	return (
		<>
			<Container
				style={{
					marginTop: isTablet ? "160px" : "160px",
					marginBottom: isTablet ? "24px" : "80px",
				}}
			>
				<Row className="mt-5 mb-5" style={{ rowGap: "1.5rem" }}>
					<Col lg={6} sm={12}>
						<h6
							style={{
								fontFamily: 'Bitter',
								fontSize: (isMobile || isTablet) ? "28px" : "36px",
								fontWeight: '600',
								color: '#0A0A0A',
								marginBottom: '36px',
							}}
						>
							{lang.ContactPage.hero.title}
						</h6>
						<p
							style={{
								alignSelf: "stretch",
								color: "#404040",
								fontWeight: 400,
								fontSize: (isMobile || isTablet) ? "16px" : '18px',
								lineHeight: (isMobile || isTablet) ? "24px" : '26px',
								marginBottom: '36px',
							}}
						>
							{lang.ContactPage.hero.description}
						</p>
						{!isMobile && !isTablet &&
							<>
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d247.93153089733482!2d106.616326!3d-6.060285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sid!4v1713760861535!5m2!1sen!2sid"
									height="328px"
									style={{ border: 0, borderRadius: '24px', marginBottom: '36px' }}
									allowfullscreen=""
									loading="lazy"
									referrerpolicy="no-referrer-when-downgrade"
								/>
								
								<div className={"d-flex flex-row"} style={{ gap: '4px' }}>
									<div className={"d-flex flex-column"}>
										<Link className="text-black">
											<Icon icon="mdi:location"/>
										</Link>
									</div>
									<div className={"d-flex flex-column"} style={{ fontSize: "20px", fontWeight: "bold" }}>
										{lang.footer.location}
									</div>
								</div>
								<div className={"d-flex flex-row"} style={{ marginBottom: '36px', fontSize: "18px", fontWeight: 400 }}>
									{lang.footer.locationDescription}
								</div>

								<div className={"d-flex flex-row"} style={{ gap: '4px' }}>
									<div className={"d-flex flex-column"}>
										<Link className="text-black">
											<Icon icon="ic:round-email" height="20" />
										</Link>
									</div>
									<div className={"d-flex flex-column"} style={{ fontSize: "20px", fontWeight: "bold" }}>
										{lang.footer.email}
									</div>
								</div>
								<div className={"d-flex flex-row"} style={{ marginBottom: '36px', fontSize: "18px", fontWeight: 400 }}>
									{lang.footer.emailDescription}
								</div>

								<div className={"d-flex flex-row"} style={{ gap: '4px' }}>
									<div className={"d-flex flex-column"}>
										<Link className="text-black">
											<Icon icon="ic:round-phone" height="18" />
										</Link>
									</div>
									<div className={"d-flex flex-column"} style={{ fontSize: "20px", fontWeight: "bold" }}>
										{lang.footer.callCenter}
									</div>
								</div>
								<div className={"d-flex flex-row"} style={{ marginBottom: '36px', fontSize:  "18px", fontWeight: 400 }}>
									{lang.footer.callCenterDescription}
								</div>
							</>
						}
					</Col>
					<Col lg={6} sm={12}>
						<Row
							style={{
								display: "flex",
								flexDirection: "column",
								rowGap: "1rem",
								paddingRight: "calc(var(--bs-gutter-x) * .5)",
								paddingLeft: "calc(var(--bs-gutter-x) * .5)"
							}}
						>
							<Form onSubmit={formik.handleSubmit} className="form-contact">
								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											fontWeight: 'Bold',
											fontSize: (isMobile || isTablet) ? "16px" : '18px',
											lineHeight: '26px',
											color: '#0A0A0A',
										}}
									>
										{lang.ContactPage.form.name}
									</Form.Label>
									<Form.Control
										style={{
											// padding: "0.625rem, 0.75rem",
											backgroundColor: "#FFFFFF",
											border: "1px solid #C2C2C2",
											height: '46px',
											borderRadius: '6px',
											marginBottom: '10px',
											//width: '100%'
										}}
										name="full_name"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.full_name}
										className="contact-form-input" type="text" placeholder={lang.ContactPage.form.name} />

									<Form.Text className="text-danger">
										{formik.touched.full_name && formik.errors.full_name ? (
											<div className="text-danger">{formik.errors.full_name}</div>
										) : null}
									</Form.Text>
								</Form.Group>
								<div style={{height: '10px'}}></div>
								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											fontWeight: 'Bold',
											fontSize: (isMobile || isTablet) ? "16px" : '18px',
											lineHeight: '26px',
											color: '#0A0A0A',
										}}
									>
										{lang.ContactPage.form.email}
									</Form.Label>
									<Form.Control
										style={{
											// padding: "0.625rem, 0.75rem",
											backgroundColor: "#FFFFFF",
											border: "1px solid #C2C2C2",
											height: '46px',
											borderRadius: '6px',
											marginBottom: '10px',
											//width: '100%'
										}}
										name="email"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.email}
										className="contact-form-input" type="text" placeholder={lang.ContactPage.form.email} />

									<Form.Text className="text-danger">
										{formik.touched.email && formik.errors.email ? (
											<div className="text-danger">{formik.errors.email}</div>
										) : null}
									</Form.Text>
								</Form.Group>
								<div style={{height: '10px'}}></div>
								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											fontWeight: 'Bold',
											fontSize: (isMobile || isTablet) ? "16px" : '18px',
											lineHeight: (isMobile || isTablet) ? "24px" : '26px',
											color: '#0A0A0A',
										}}
									>
										{lang.ContactPage.form.phone}
									</Form.Label>
									<Form.Control
										style={{
											// padding: "0.625rem, 0.75rem",
											backgroundColor: "#FFFFFF",
											border: "1px solid #C2C2C2",
											height: '46px',
											borderRadius: '6px',
											//width: '100%'
										}}
										name="phone_number"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.phone_number}
										className="contact-form-input" type="number" placeholder={lang.ContactPage.form.phone} />

									<Form.Text className="text-danger">
										{formik.touched.phone_number && formik.errors.phone_number ? (
											<div className="text-danger">{formik.errors.phone_number}</div>
										) : null}
									</Form.Text>
								</Form.Group>
								<div style={{height: '10px'}}></div>
								<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
									<Form.Label
										style={{
											fontWeight: 'Bold',
											fontSize: (isMobile || isTablet) ? "16px" : '18px',
											lineHeight: (isMobile || isTablet) ? "24px" : '26px',
											color: '#0A0A0A',
										}}
									>
										{lang.ContactPage.form.message}
									</Form.Label>
									<Form.Control
										style={{
											// padding: "0.625rem, 0.75rem",
											backgroundColor: "#FFFFFF",
											border: "1px solid #C2C2C2",
											height: '130px',
											borderRadius: '6px',
											resize: 'none',
											//width: '100%'
										}}
										as="textarea"
										rows={4}
										name="message"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.message}
										className="contact-form-input"

										placeholder={lang.ContactPage.form.message} />

									<Form.Text className="text-danger">
										{formik.touched.message && formik.errors.message ? (
											<div className="text-danger">{formik.errors.message}</div>
										) : null}
									</Form.Text>

								</Form.Group>
								<div className="d-grid">
									<Button
										type="submit"
										block
										style={{
											textAlign: 'center',
											width: '100%',
											height: '48px',
											borderRadius: '24px',
											backgroundColor: '#E60000',
											fontWeight: 600,
											cursor: 'pointer',
											display: 'flex',
											border: 0,
											alignItems: 'center',
											justifyContent: 'center',
											marginTop: '36px'
										}}
									>
										{lang.ContactPage.form.send}
									</Button>
								</div>
							</Form>
						</Row>
					</Col>
				</Row>
				{(isMobile || isTablet) &&
					<Row>
						<Col lg={6} sm={12}>
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d247.93153089733482!2d106.616326!3d-6.060285!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sid!4v1713760861535!5m2!1sen!2sid"
								width="100%"
								height="328px"
								style={{ border: 0, borderRadius: '24px', marginBottom: '36px' }}
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
							/>
							<div className={"d-flex flex-row"} style={{ gap: '4px' }}>
								<div className={"d-flex flex-column"}>
									<Link className="text-black">
										<Icon icon="mdi:location"/>
									</Link>
								</div>
								<div className={"d-flex flex-column"} style={{ fontSize: "18px", fontWeight: "bold" }}>
									{lang.footer.location}
								</div>
							</div>
							<div className={"d-flex flex-row"} style={{ marginBottom: '36px', fontSize: "16px", fontWeight: 400 }}>
								{lang.footer.locationDescription}
							</div>

							<div className={"d-flex flex-row"} style={{ gap: '4px' }}>
								<div className={"d-flex flex-column"}>
									<Link className="text-black">
										<Icon icon="ic:round-email" height="20" />
									</Link>
								</div>
								<div className={"d-flex flex-column"} style={{ fontSize: "18px", fontWeight: "bold" }}>
									{lang.footer.email}
								</div>
							</div>
							<div className={"d-flex flex-row"} style={{ marginBottom: '36px', fontSize: "16px", fontWeight: 400 }}>
								{lang.footer.emailDescription}
							</div>

							<div className={"d-flex flex-row"} style={{ gap: '4px' }}>
								<div className={"d-flex flex-column"}>
									<Link className="text-black">
										<Icon icon="ic:round-phone" height="18" />
									</Link>
								</div>
								<div className={"d-flex flex-column"} style={{ fontSize: "18px", fontWeight: "bold" }}>
									{lang.footer.callCenter}
								</div>
							</div>
							<div className={"d-flex flex-row"} style={{ marginBottom: '36px', fontSize: "16px", fontWeight: 400 }}>
								{lang.footer.callCenterDescription}
							</div>
						</Col>
					</Row>
				}
			</Container>
		</>
	)
}
