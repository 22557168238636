import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useOutletContext, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
    A11y,
    Autoplay,
    Keyboard,
    Navigation,
    Pagination,
    Scrollbar
} from "swiper/modules";

export default function ProductDetailPage() {
    const [isMobile, isTablet, lang, setLang] = useOutletContext()
    const {id} = useParams()
    const [product, setProduct] = useState(null)
    const aboutProductRef = useRef(null);
    const productsRef = useRef(null);
    const applicationsRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const init = () => {
        if (lang && id) {
            let result = lang.ProductDetailPage.filter((value) => {
                return value?.id === parseInt(id);
            });
            setProduct(result?.length > 0 ? result[0] : null);
        }
    };

    useEffect(() => {
        init();
    }, [id, lang]); 

    const handleClick = (index) => {
        setActiveIndex(index);
    
        let offset = 100;
    
        if (index === 0 && aboutProductRef.current) {
            const rect = aboutProductRef.current.getBoundingClientRect();
            window.scrollTo({
                top: window.scrollY + rect.top - offset,
                behavior: 'smooth'
            });
        } else if (index === 1 && productsRef.current) {
            const rect = productsRef.current.getBoundingClientRect();
            window.scrollTo({
                top: window.scrollY + rect.top - offset,
                behavior: 'smooth'
            });
        } else if (index === 2 && applicationsRef.current) {
            const rect = applicationsRef.current.getBoundingClientRect();
            window.scrollTo({
                top: window.scrollY + rect.top - offset,
                behavior: 'smooth'
            });
        }
    };
    

    const renderHeroSection = () => {
        return (
            <>
                <Container
                    style={{
                        marginTop: '100px',
                        marginBottom: '12px'
                    }}
                >
                    <Row>
                        <div
                            style={{
                                display: 'flex',
                                margin: "8px 0 12px 0",
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: 600,
                                    fontSize: (isMobile || isTablet) ? '14px' : '16px',
                                    lineHeight: (isMobile || isTablet) ? '20px' : '24px',
                                    color: activeIndex === 0 ? "#E60000" : "#0A0A0A",
                                    cursor: "pointer",
                                    textDecoration: activeIndex === 0 ? "underline" : "none",
                                    textUnderlineOffset: "10px",
                                    marginRight: '24px',
                                }}
                                onClick={() => handleClick(0)}
                            >
                                {product?.hero?.name}
                            </div>
                            <div
                                style={{
                                    fontWeight: 600,
                                    fontSize: (isMobile || isTablet) ? '14px' : '16px',
                                    lineHeight: (isMobile || isTablet) ? '20px' : '24px',
                                    color: activeIndex === 1 ? "#E60000" : "#0A0A0A",
                                    cursor: "pointer",
                                    textDecoration: activeIndex === 1 ? "underline" : "none",
                                    textUnderlineOffset: "10px",
                                    marginRight: '24px',
                                }}
                                onClick={() => handleClick(1)}
                            >
                                {product?.productType?.title}
                            </div>
                            <div
                                style={{
                                    fontWeight: 600,
                                    fontSize: (isMobile || isTablet) ? '14px' : '16px',
                                    lineHeight: (isMobile || isTablet) ? '20px' : '24px',
                                    color: activeIndex === 2 ? "#E60000" : "#0A0A0A",
                                    cursor: "pointer",
                                    textDecoration: activeIndex === 2 ? "underline" : "none",
                                    textUnderlineOffset: "10px",
                                }}
                                onClick={() => handleClick(2)}
                            >
                                {product?.application?.title}
                            </div>
                        </div>
                    </Row>
                </Container>
            </>
        )
    }
    const renderImagesSection = () => {
        return (
            <div style={{ position: 'relative', width: '100%' }}>
                <Swiper
                    style={{width: '100%', display: 'flex', justifyContent: 'center'}}
                    keyboard={{
                        enabled: true
                    }}
                    pagination={{
                        activeBullets: true,
                        dynamicBullets: true,
                        clickable: true,
                        renderBullet: (className) => {
                            // return `<span class="${className} custom-bullets" style="width: 15px; height: 15px; background-color: white; border-radius: 50%;"></span>`;
                            // return `<span class="${className}"></span>`;
                            return `
                                <span class="${className}" style="
                                    width: 15px;
                                    height: 15px;
                                    background-color: rgba(255, 255, 255, 0.36);
                                    border-radius: 50%;
                                    margin: 0 6px;
                                    transition: background-color 0.3s;
                                "></span>
                            `;
                        },
                    }}
                    navigation={{
                        nextEl: ".custom-swiper-button-next",
                        prevEl: ".custom-swiper-button-prev",
                    }}
                    modules={[Navigation, Pagination, Scrollbar, A11y, Keyboard, Autoplay]}
                    slidesPerView={1}
                >
                    {product?.imageList?.map((about, index) => (
                        <SwiperSlide key={index}>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img
                                    style={{
                                        height: isMobile ? "280px" : isTablet ? "420px" : "640px",
                                        width: "100%",
                                        objectFit: "cover",
                                    }}
                                    // alt={"about-section-image"}
                                    src={about.image}
                                />
                            </div>
            
                        </SwiperSlide>
                    ))}

                    <div
                        className="custom-swiper-button-next"
                        style={{
                            position: "absolute",
                            top: "45%",
                            right: "0",
                            zIndex: 10,
                            cursor: "pointer",
                            borderRadius: "50%",
                            padding: "5px"
                        }}
                    >
                        <Icon icon="uiw:right-circle" width="30" height="30"  style={{ color: "#fff" }} />
                    </div>
                    <div
                        className="custom-swiper-button-prev"
                        style={{
                            position: "absolute",
                            top: "45%",
                            left: "-0",
                            zIndex: 10,
                            cursor: "pointer",
                            borderRadius: "50%",
                            padding: "5px"
                        }}
                    >
                        <Icon icon="uiw:right-circle" width="30" height="30"  style={{ color: "#fff" }} rotate={'180deg'} />
                    </div>
                </Swiper>
            </div>
        )
    }
    const renderAboutProductSection = () => {
        return (
            <>
                <Container
                    ref={aboutProductRef}
                    style={{
                        display: "flex",
                        marginTop: '60px',
                        marginBottom: '60px',
                    }}
                >
                    <Row>
                        <Col md={12}>
                            <h3
                                style={{
                                    fontSize: (isMobile || isTablet) ? '28px' : "36px",
                                    lineHeight: (isMobile || isTablet) ? '36px' : '48px',
                                    fontFamily: 'Bitter',
                                    fontWeight: 600,
                                    color: '#0A0A0A',
                                    marginBottom: '24px',
                                }}
                            >
                                {product?.hero?.title}
                            </h3>
                            <p
                                style={{
                                    alignSelf: "stretch",
                                    color: "#404040",
                                    fontWeight: 400,
                                    fontSize: (isMobile || isTablet) ? '16px' : '18px',
                                    lineHeight: (isMobile || isTablet) ? '24px' : '26px',
                                }}
                            >
                                {product?.hero?.description}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
    const renderProductsSection = () => {
        return (
            <Container
                ref={productsRef}
                style={{
                    marginTop: '60px',
                    marginBottom: '60px',
                }}
            >
                <Row style={{marginBottom: '32px'}}>
                    <Col
                        className={
                            "d-flex flex-column" +
                            `${isTablet ? "align-items-start" : ""}`
                        }
                        md={7}
                    >
                        <h3
                            style={{
                                fontSize: (isMobile || isTablet) ? '24px' : '32px',
                                fontWeight: '600',
                                fontFamily: 'Bitter',
                            }}
                        >
                            {product?.productType?.title}
                        </h3>
                    </Col>
                </Row>

                <Row className={"mt-1 mb-5"}>
                    {product?.productType?.list.map((v, index) => {
                        return (
                            <>
                                <Col
                                    lg={3} sm={6}
                                    style={{
                                        height: isTablet ? "fit-content" : "auto",
                                        marginBottom: "24px",
                                        gap: '24px'
                                    }}
                                >
                                    <div 
                                        style={{ 
                                            position: 'relative',
                                            width: '100%',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <img
                                            style={{
                                                height: '100%',
                                                width: "100%",
                                                background: `linear-gradient(180deg, rgba(10, 10, 10, 0) 50%, rgba(10, 10, 10, 0.48) 88%)`,
                                                borderRadius: '24px',
                                                objectFit: "cover"
                                            }}
                                            src={v?.image}
                                        />
                                        <div 
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                borderRadius: '24px',
                                                background: 'linear-gradient(180deg, rgba(10, 10, 10, 0) 50%, rgba(10, 10, 10, 0.48) 88%)',
                                            }}
                                        ></div>
                                        <h4 style={{
                                            fontSize: (isMobile || isTablet) ? '18px' : "20px",
                                            fontWeight: 700,
                                            lineHeight: (isMobile || isTablet) ? '26px' : '28px',
                                            fontFamily: 'Bitter',
                                            color: 'white',
                                            position: 'absolute',
                                            bottom: (isMobile || isTablet) ? '24px' : '32px',
                                            left: (isMobile || isTablet) ? '20px' : '24px',
                                        }}>
                                            {v?.name}
                                        </h4>
                                    </div>
                                </Col>
                            </>
                        )
                    })}
                </Row>
            </Container>
        )
    }
    const renderApplicationSection = () => {
        return (
            <Container ref={applicationsRef}>
                <Row style={{marginBottom: '24px'}}>
                    <Col
                        className={
                            `${isMobile || isTablet ? "align-items-start" : ""}`
                        }
                    >
                        <h3
                            style={{
                                fontSize: (isMobile || isTablet) ? '24px' : '32px',
                                fontWeight: '600',
                                fontFamily: 'Bitter',
                                marginBottom: '24px',
                                lineHeight: (isMobile || isTablet) ? '32px' : '44px',
                            }}
                        >
                            {product?.application?.title}
                        </h3>
                        <p
                            style={{
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '24px',
                            }}
                        >
                            {product?.application?.description}
                        </p>
                    </Col>
                </Row>

                <Row className={"mt-1 mb-5 align-items-center justify-content-center"}>
                    {product?.application?.list.map((v, index) => {
                        return (
                            <>
                                <Col
                                    lg={4} sm={6}
                                    style={{
                                        height: isTablet ? "fit-content" : "auto",
                                        marginBottom: "24px",
                                        gap: '24px',
                                        // display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img
                                        style={{
                                            height: (isMobile || isTablet) ? '240px' : '320px',
                                            width: "100%",
                                            borderRadius: '24px',
                                            marginBottom: '12px',
                                            objectFit: "cover",
                                        }}
                                        src={v?.image}
                                    />
                                    <p style={{
                                        textAlign: 'center',
                                        fontSize: (isMobile || isTablet) ? '16px' : "18px",
                                        fontWeight: 600,
                                        lineHeight: '26px',
                                        color: '#0A0A0A',
                                        alignItems: 'center',
                                    }}>
                                        <b>{v?.name} </b>{v?.caption}
                                    </p>
                                </Col>
                            </>
                        )
                    })}
                </Row>
            </Container>
        )
    }
    return(
        <div className={"d-flex flex-column"}>
            {renderHeroSection()}
            {renderImagesSection()}
            {renderAboutProductSection()}
            {renderProductsSection()}
            {renderApplicationSection()}
        </div>
    )
}
