export default class Palette {
    static THEME_BLUE = "#214474"
    static SHADOW = "#20396510"
    static THEME_ACCENT_BLUE = "#36528B"
    static THEME_INACTIVE_GREY = "#999999"
    static THEME_WHITE = "#FFFFFF"
    static THEME_TEXT_MAIN = '#666'
    static THEME_TEXT_DARK = '#121212'
    static THEME_LINK_PRIMARY = '#214474'
    static YELLOW = '#F3F56F'
}