import { useRoutes } from "react-router-dom"
import HomeLayout from "./layout/HomeLayout"
import AboutPage from "./pages/AboutPage"
import ContactPage from "./pages/ContactPage"
import HomePage from "./pages/HomePage"
import ProductDetailPage from "./pages/ProductDetailPage"

export default function Router() {
	let isLoggedIn = !!localStorage.getItem("token")

	let tempRoutes = [
		{
			path: "",
			element: <HomeLayout />,
			children: [
				{ path: "/", element: <HomePage /> },
				{ path: "/product/:id", key:'product', element: <ProductDetailPage /> },
				{ path: "/about", element: <AboutPage /> },
				{ path: "/contact", element: <ContactPage /> },
			]
		}
	]

	const routes = useRoutes(tempRoutes)

	return routes
}
