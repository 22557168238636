import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import './App.css';
import Router from "./components/Router";
function App() {
    return (
        <>
            <Helmet>
                <title>PT. Pagar Utama Panganindo</title>
                <meta name="description" content="Pilihan Terbaik untuk Makanan Sehat dan Berkualitas"/>
            </Helmet>
            <BrowserRouter>
                {/* <ScrollToTop/> */}
                <Router />
            </BrowserRouter>
        </>

    );
}

export default App;
