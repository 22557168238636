import aboutHome from "../../../assets/about-home.png";

import certificateImage1 from "../../../assets/about/certificate_1.png";
import certificateImage2 from "../../../assets/about/certificate_2.png";
import certificateImage3 from "../../../assets/about/certificate_3.png";
import certificateImage4 from "../../../assets/about/certificate_4.png";

import whyUs2 from "../../../assets/about/harga_kompetitif.png";
import whyUs5 from "../../../assets/about/inovasi_lanjutan.png";
import whyUs1 from "../../../assets/about/kualitas_tinggi.png";
import whyUs4 from "../../../assets/about/pelayanan_baik.png";
import whyUs3 from "../../../assets/about/produk_bersertifikasi.png";
import aboutUs from "../../../assets/about/tentang_kami_1.png";

import productImage1 from "../../../assets/product/product_image_1.png";
import productImage2 from "../../../assets/product/product_image_2.png";
import productImage3 from "../../../assets/product/product_image_3.png";
import productImage4 from "../../../assets/product/product_image_4.png";
import productImage5 from "../../../assets/product/product_image_5.png";

import detailImage1 from "../../../assets/productDetails/product_detail_1_1.png";
import detailImage2 from "../../../assets/productDetails/product_detail_1_2.png";
import detailImage3 from "../../../assets/productDetails/product_detail_1_3.png";
import detailImage4 from "../../../assets/productDetails/product_detail_1_4.png";
import detailImage5 from "../../../assets/productDetails/product_detail_1_5.png";

import detailApplication1 from "../../../assets/productDetails/detail_application_1_1.png";
import detailApplication2 from "../../../assets/productDetails/detail_application_1_2.png";
import detailApplication3 from "../../../assets/productDetails/detail_application_1_3.png";

import detailProductType1 from "../../../assets/productDetails/ragam_produk_1_1.png";
import detailProductType2 from "../../../assets/productDetails/ragam_produk_1_2.png";
import detailProductType3 from "../../../assets/productDetails/ragam_produk_1_3.png";
import detailProductType4 from "../../../assets/productDetails/ragam_produk_1_4.png";
import detailProductType5 from "../../../assets/productDetails/ragam_produk_1_5.png";
import detailProductType6 from "../../../assets/productDetails/ragam_produk_1_6.png";
import detailProductType7 from "../../../assets/productDetails/ragam_produk_1_7.png";
import detailProductType8 from "../../../assets/productDetails/ragam_produk_1_8.png";



export default {
    MENU_HEADER: [
        {name: "Home", path: '/'},
        {
            name: "Our Products",
            submenu: [
                {
                    name: "Bread Crumbs",
                    path: '/product/1'
                },
                {
                    name: "MSG",
                    path: '/product/2'
                },
                {
                    name: "Corn Starch",
                    path: '/product/3'
                },
                {
                    name: "Tapioca",
                    path: '/product/4'
                },
                {
                    name: "Chili Powder",
                    path: '/product/5'
                }
            ]
        },
        {name: "About Us", path: '/about'},
        // {name: "CSR", path: '/csr'},
        {name: "Contact Us", path: '/contact'}
    ],
    MENU_FOOTER: [
        {name: "Home", path: '/'},
        {
            name: "Our Products",
            submenu: [
                {
                    name: "Bread Crumbs",
                    path: '/product/1'
                },
                {
                    name: "MSG",
                    path: '/product/2'
                },
                {
                    name: "Corn Starch",
                    path: '/product/3'
                },
                {
                    name: "Tapioca",
                    path: '/product/4'
                },
                {
                    name: "Chili Powder",
                    path: '/product/5'
                }
            ]
        },
        {name: "About Us", path: '/about'}
    ],   
    UpperFooter: {
        name: "Interested in our products?",
        next: "Contact Us"
    }, 
    HomePage: {
        hero: [
            {
                title: "The Best Choice for Healthy and Quality Food",
                description: (
                    <>
                        Providing various needs for your cooking.
                    </>
                ),
                button1: "Contact Us",
                button2: "See Our Products"
            }
        ],
        about: {
            title: "About PT. Pagar Utama Panganindo",
            description: (
                <>
                    PT. Pagar Utama Panganindo is a manufacturing company established in 2023. We are committed to providing various high-quality ingredients to perfect your dishes.
                    <br/><br/>
                    Our vision is to become a widely recognized innovative product manufacturer with a positive reputation. Our mission is to prioritize quality, reliability, and consistent excellence in all products. We are committed to using only quality ingredients, offering our products at the best prices, and providing the best service that will satisfy consumers.
                </>
            ),
            more: "Learn More"
        },
        image: [
            {
                image: aboutHome
            },
            {
                image: aboutHome
            },
            {
                image: aboutHome
            },
            {
                image: aboutHome
            },
            {
                image: aboutHome
            }
        ],
        product: {
            title: "Our Products",
            description: "We provide a variety of high-quality food ingredients made from premium materials.",
            more: "See All"
        },
        list: [
            {
                title: "Bread Crumbs",
                image: productImage1,
                link: '/product/1'
            },
            {
                title: "MSG",
                image: productImage2,
                link: '/product/2'
            },
            {
                title: "Corn Starch",
                image: productImage3,
                link: '/product/3'
            },
            {
                title: "Tapioca",
                image: productImage4,
                link: '/product/4'
            },
            {
                title: "Chili Powder",
                image: productImage5,
                link: '/product/5'
            }
        ],
        WhyUs: {
            title: "Why Choose Us?",
            description: "We are committed to producing the best products for your food needs. Here are some reasons why you should choose our products.",
            list: [
                {
                    title: "High Quality",
                    description: 'We ensure that every product we produce meets the highest quality standards, using selected ingredients and advanced production processes to ensure our products are not only delicious but also nutritious.',
                    image: whyUs1
                },
                {
                    title: "Competitive Prices",
                    description: 'We offer products at competitive prices without compromising on quality, providing the best value for our consumers. We also offer special prices for bulk purchases or contract manufacturing.',
                    image: whyUs2
                },
                {
                    title: "Certified Products",
                    description: 'All our products undergo certification processes to ensure safety and compliance with regulations.',
                    image: whyUs3
                },
                {
                    title: "Excellent Customer Service",
                    description: 'We are always ready to listen and respond to feedback and suggestions from customers to continuously improve our product quality and services.',
                    image: whyUs4
                },
                {
                    title: "Continuous Innovation",
                    description: 'We constantly strive to innovate in product development, keeping up with market trends and needs to introduce new products that meet consumer tastes.',
                    image: whyUs5
                }
            ]
        },
        video: {
            title: "Videos",
            description: "Immerse yourself in our world through captivating videos showcasing our products, processes, and the passion behind everything we do.",
            list: [
                {
                    title: "This is not just any dry noodle"
                },
                {
                    title: "Dry Noodle Production Process"
                },
                {
                    title: "Flour Processing"
                },
                {
                    title: "Dry Noodle Processing"
                }
            ],
            more: "See All"
        }
    },    
    AboutPage: {
        hero: {
            title: "About Us",
            description: (
                <>
                    We are a manufacturing company established in 2023, founded with the vision of becoming a leader in the food industry by providing a variety of high-quality products that meet all your food needs.
                    <br/><br/>
                    Since our inception, we have focused on innovation and quality, ensuring that every product we produce is not only delicious but also healthy and safe to consume. With a diverse range of products we offer, we strive to meet the tastes and needs of various consumer segments. 
                    <br/><br/>
                    We are committed to using the best ingredients and advanced production processes while continuously adapting to evolving market trends and needs to always provide the best for you. As a customer-oriented company, we are also always ready to listen to feedback and suggestions to continually improve our quality and service, so you can always enjoy quality food products from us.
                </>
            )
        },
        images: {
            image_1: aboutUs,
            image_2: aboutUs,
            image_3: aboutUs,
            image_4: aboutUs,
            image_5: aboutUs
        },
        visionMission: {
            title: "Vision & Mission",
            description: 
                'Our vision is to become a widely recognized innovative product manufacturer with a positive reputation. Our mission is to prioritize quality, reliability, and consistent excellence in all products. We are committed to using only quality ingredients, offering our products at the best prices, and providing excellent service that satisfies consumers.'
        },
        certificate: {
            title: "Certificates",
            list: [
                { name: 'Halal Certificate from LPPOM MUI', image: certificateImage1 },
                { name: 'MD Number from BPOM RI', image: certificateImage2 },
                { name: 'ISO 22000:2005', image: certificateImage3 },
                { name: 'MD Number from BPOM RI', image: certificateImage4 }
            ]
        }
    },  
    ProductDetailPage: [
        {
            id: 1,
            hero: {
                title: "Bread Crumbs",
                name: "Summary",
                description:
                    "To meet customer demands and needs, PT. Pagar Utama Panganindo offers a variety of bread crumb types. There are 2 types of bread crumbs produced by PT. Pagar Utama Panganindo: white and yellow, expected to fulfill diverse customer needs."
            },
            imageList: [
                { image: detailImage1 },
                { image: detailImage2 },
                { image: detailImage3 },
                { image: detailImage4 },
                { image: detailImage5 }
            ],
            application: {
                title: "Application Uses",
                description: "Here are some ways you can apply bread crumbs.",
                list: [
                    { image: detailApplication1, name: "Coating", caption: "(Dough coating)" },
                    { image: detailApplication2, name: "Topping", caption: "(Dough topping)" },
                    { image: detailApplication3, name: "Stuffing", caption: "(Filling dough)" }
                ]
            },
            productType: {
                title: "Product Varieties",
                list: [
                    { image: detailProductType1, name: "White Flour 200g" },
                    { image: detailProductType2, name: "White Flour 500g" },
                    { image: detailProductType3, name: "White Flour 1kg" },
                    { image: detailProductType4, name: "White Flour 10kg" },
                    { image: detailProductType5, name: "Yellow Flour 200g" },
                    { image: detailProductType6, name: "Yellow Flour 500g" },
                    { image: detailProductType7, name: "Yellow Flour 1kg" },
                    { image: detailProductType8, name: "Yellow Flour 10kg" }
                ]
            }
        },
        {
            id: 2,
            hero: {
                title: "MSG",
                name: "Summary",
                description:
                    "To meet customer demands and needs, PT. Pagar Utama Panganindo offers various types of MSG. The products are designed to meet diverse customer needs."
            },
            imageList: [
                { image: detailImage1 },
                { image: detailImage2 },
                { image: detailImage3 },
                { image: detailImage4 },
                { image: detailImage5 }
            ],
            application: {
                title: "Application Uses",
                description: "Here are some ways you can use MSG.",
                list: [
                    { image: detailApplication1, name: "Coating", caption: "(Dough coating)" },
                    { image: detailApplication2, name: "Topping", caption: "(Dough topping)" },
                    { image: detailApplication3, name: "Stuffing", caption: "(Filling dough)" }
                ]
            },
            productType: {
                title: "Product Varieties",
                list: [
                    { image: detailProductType1, name: "White Flour 200g" },
                    { image: detailProductType2, name: "White Flour 500g" },
                    { image: detailProductType3, name: "White Flour 1kg" },
                    { image: detailProductType4, name: "White Flour 10kg" },
                    { image: detailProductType5, name: "Yellow Flour 200g" },
                    { image: detailProductType6, name: "Yellow Flour 500g" },
                    { image: detailProductType7, name: "Yellow Flour 1kg" },
                    { image: detailProductType8, name: "Yellow Flour 10kg" }
                ]
            }
        },
        {
            id: 3,
            hero: {
                title: "Corn Starch",
                name: "Summary",
                description:
                    "To meet customer demands and needs, PT. Pagar Utama Panganindo offers various types of corn starch. The products are designed to meet diverse customer needs."
            },
            imageList: [
                { image: detailImage1 },
                { image: detailImage2 },
                { image: detailImage3 },
                { image: detailImage4 },
                { image: detailImage5 }
            ],
            application: {
                title: "Application Uses",
                description: "Here are some ways you can use corn starch.",
                list: [
                    { image: detailApplication1, name: "Coating", caption: "(Dough coating)" },
                    { image: detailApplication2, name: "Topping", caption: "(Dough topping)" },
                    { image: detailApplication3, name: "Stuffing", caption: "(Filling dough)" }
                ]
            },
            productType: {
                title: "Product Varieties",
                list: [
                    { image: detailProductType1, name: "White Flour 200g" },
                    { image: detailProductType2, name: "White Flour 500g" },
                    { image: detailProductType3, name: "White Flour 1kg" },
                    { image: detailProductType4, name: "White Flour 10kg" },
                    { image: detailProductType5, name: "Yellow Flour 200g" },
                    { image: detailProductType6, name: "Yellow Flour 500g" },
                    { image: detailProductType7, name: "Yellow Flour 1kg" },
                    { image: detailProductType8, name: "Yellow Flour 10kg" }
                ]
            }
        },
        {
            id: 4,
            hero: {
                title: "Tapioca",
                name: "Summary",
                description:
                    "To meet customer demands and needs, PT. Pagar Utama Panganindo offers various types of tapioca. The products are designed to meet diverse customer needs."
            },
            imageList: [
                { image: detailImage1 },
                { image: detailImage2 },
                { image: detailImage3 },
                { image: detailImage4 },
                { image: detailImage5 }
            ],
            application: {
                title: "Application Uses",
                description: "Here are some ways you can use tapioca.",
                list: [
                    { image: detailApplication1, name: "Coating", caption: "(Dough coating)" },
                    { image: detailApplication2, name: "Topping", caption: "(Dough topping)" },
                    { image: detailApplication3, name: "Stuffing", caption: "(Filling dough)" }
                ]
            },
            productType: {
                title: "Product Varieties",
                list: [
                    { image: detailProductType1, name: "White Flour 200g" },
                    { image: detailProductType2, name: "White Flour 500g" },
                    { image: detailProductType3, name: "White Flour 1kg" },
                    { image: detailProductType4, name: "White Flour 10kg" },
                    { image: detailProductType5, name: "Yellow Flour 200g" },
                    { image: detailProductType6, name: "Yellow Flour 500g" },
                    { image: detailProductType7, name: "Yellow Flour 1kg" },
                    { image: detailProductType8, name: "Yellow Flour 10kg" }
                ]
            }
        },
        {
            id: 5,
            hero: {
                title: "Chili Powder",
                name: "Summary",
                description:
                    "To meet customer demands and needs, PT. Pagar Utama Panganindo offers various types of chili powder. The products are designed to meet diverse customer needs."
            },
            imageList: [
                { image: detailImage1 },
                { image: detailImage2 },
                { image: detailImage3 },
                { image: detailImage4 },
                { image: detailImage5 }
            ],
            application: {
                title: "Application Uses",
                description: "Here are some ways you can use chili powder.",
                list: [
                    { image: detailApplication1, name: "Coating", caption: "(Dough coating)" },
                    { image: detailApplication2, name: "Topping", caption: "(Dough topping)" },
                    { image: detailApplication3, name: "Stuffing", caption: "(Filling dough)" }
                ]
            },
            productType: {
                title: "Product Varieties",
                list: [
                    { image: detailProductType1, name: "White Flour 200g" },
                    { image: detailProductType2, name: "White Flour 500g" },
                    { image: detailProductType3, name: "White Flour 1kg" },
                    { image: detailProductType4, name: "White Flour 10kg" },
                    { image: detailProductType5, name: "Yellow Flour 200g" },
                    { image: detailProductType6, name: "Yellow Flour 500g" },
                    { image: detailProductType7, name: "Yellow Flour 1kg" },
                    { image: detailProductType8, name: "Yellow Flour 10kg" }
                ]
            }
        }
    ],    
    ContactPage: {
        hero: {
            title: "Interested in our products?",
            description:
                "Want to place an order? Form a partnership? Or just have questions about our products? Fill out the form below and one of our team members will get in touch with you soon."
        },
        form: {
            name: "Name",
            phone: "Whatsapp/Phone Number",
            email: "Email",
            message: "Your Inquiry",
            send: "Send"
        }
    },
    footer: {
        location: "Headquarter Address",
        locationDescription: "PT. Pagar Utama Panganindo, Ruko Duta Gardenia Blok D/28, Jurumudi Baru, Tangerang, Banten, 15124",
        shortcutLink: "Shortcut Link",
        email: "Email",
        emailDescription: "marcom@smwfood.com",
        callCenter: "Call Center",
        callCenterDescription: "+62 852-8372-8394"
    }
}
