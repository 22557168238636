
import apiConfig from "./apiConfig";

export default class API {
    static set = async (endpoint, method, body) => {

        let token = sessionStorage.token || localStorage.token;

        console.log(`API ACCESS [${method}]: ` + endpoint);
        let request = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: token ? `bearer ${token}` : null,
                Accept: 'application/json',
            },
            body: JSON.stringify(body)
            // body: "afdadsf"
        };

        let response = await fetch(apiConfig.baseUrl + endpoint, request);

        if (response.ok) {
            return response.json();
        }

        let error = await response.json();
        console.log(error)
        console.log(error.code)

        if (error.code === 'JWT_EXPIRED' || error.code === 'NO_TOKEN_PROVIDED' || error.code === 'INVALID_TOKEN' || error.code === 'BAD_TOKEN_FORMAT' || error.code === 'NO_SECRET_DEFINED' || error.code === 'JWT_MALFORMED' || error.code === 'JWT_MALFORMED' || error.code === 'JWT_EXPIRED' || error.code === "SUBSCRIPTION_EXPIRED") {
            localStorage.removeItem("admin")
            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("role")

            window.location = "/login"
            window.location.reload();

        }

        return
        throw error;
    }
}


