import aboutHome from "../../../assets/about-home.png";

import certificateImage1 from "../../../assets/about/certificate_1.png";
import certificateImage2 from "../../../assets/about/certificate_2.png";
import certificateImage3 from "../../../assets/about/certificate_3.png";
import certificateImage4 from "../../../assets/about/certificate_4.png";

import whyUs2 from "../../../assets/about/harga_kompetitif.png";
import whyUs5 from "../../../assets/about/inovasi_lanjutan.png";
import whyUs1 from "../../../assets/about/kualitas_tinggi.png";
import whyUs4 from "../../../assets/about/pelayanan_baik.png";
import whyUs3 from "../../../assets/about/produk_bersertifikasi.png";
import aboutUs from "../../../assets/about/tentang_kami_1.png";

import productImage1 from "../../../assets/product/product_image_1.png";
import productImage2 from "../../../assets/product/product_image_2.png";
import productImage3 from "../../../assets/product/product_image_3.png";
import productImage4 from "../../../assets/product/product_image_4.png";
import productImage5 from "../../../assets/product/product_image_5.png";

import detailImage1 from "../../../assets/productDetails/product_detail_1_1.png";
import detailImage2 from "../../../assets/productDetails/product_detail_1_2.png";
import detailImage3 from "../../../assets/productDetails/product_detail_1_3.png";
import detailImage4 from "../../../assets/productDetails/product_detail_1_4.png";
import detailImage5 from "../../../assets/productDetails/product_detail_1_5.png";

import detailApplication1 from "../../../assets/productDetails/detail_application_1_1.png";
import detailApplication2 from "../../../assets/productDetails/detail_application_1_2.png";
import detailApplication3 from "../../../assets/productDetails/detail_application_1_3.png";

import detailProductType1 from "../../../assets/productDetails/ragam_produk_1_1.png";
import detailProductType2 from "../../../assets/productDetails/ragam_produk_1_2.png";
import detailProductType3 from "../../../assets/productDetails/ragam_produk_1_3.png";
import detailProductType4 from "../../../assets/productDetails/ragam_produk_1_4.png";
import detailProductType5 from "../../../assets/productDetails/ragam_produk_1_5.png";
import detailProductType6 from "../../../assets/productDetails/ragam_produk_1_6.png";
import detailProductType7 from "../../../assets/productDetails/ragam_produk_1_7.png";
import detailProductType8 from "../../../assets/productDetails/ragam_produk_1_8.png";

export default {
    MENU_HEADER: [
        {name: "Beranda", path: '/'},
        {
            name: "Produk Kami",
            submenu: [
                {
                    name: "Tepung Roti",
                    path: '/product/1'
                },
                {
                    name: "MSG",
                    path: '/product/2'
                },
                {
                    name: "Tepung Maizena",
                    path: '/product/3'
                },
                {
                    name: "Tapioka",
                    path: '/product/4'
                },
                {
                    name: "Bubuk Cabe",
                    path: '/product/5'
                }
            ]
        },
        {name: "Tentang Kami", path: '/about'},
        // {name: "CSR", path: '/csr'},
        {name: "Hubungi Kami", path: '/contact'}
    ],
    MENU_FOOTER: [
        {name: "Beranda", path: '/'},
        {
            name: "Produk Kami",
            submenu: [
                {
                    name: "Tepung Roti",
                    path: '/product/1'
                },
                {
                    name: "MSG",
                    path: '/product/2'
                },
                {
                    name: "Tepung Maizena",
                    path: '/product/3'
                },
                {
                    name: "Tapioka",
                    path: '/product/4'
                },
                {
                    name: "Bubuk Cabe",
                    path: '/product/5'
                }
            ]
        },
        {name: "Tentang Kami", path: '/about'},
        // {name: "CSR", path: '/csr'},
        // {name : "Our Services", path : "/services"},
        //{name : "Our Clients", path : "/services"},
    ],
    UpperFooter: {
        name: "Tertarik dengan produk kami?",
        next: "Hubungi Kami"
    },
    HomePage: {
        hero: [
            {
                title: "Pilihan Terbaik untuk Makanan Sehat dan Berkualitas",
                description: (
                    <>
                        Menyediakan berbagai kebutuhan masakan Anda
                    </>
                ),
                button1: "Hubungi Kami",
                button2: "Lihat Produk Kami"
            },
        ],
        about: {
            title: "Tentang PT. Pagar Utama Panganindo",
            description:(
                <>
                    PT. Pagar Utama Panganindo adalah perusahaan yang bergerak di bidang manufaktur sejak tahun 2023. Kami berkomitmen untuk menyediakan berbagai bahan masakan berkualitas untuk menyempurnakan masakan Anda.
                    <br/><br/>
                    Visi kami adalah menjadi manufaktur produk inovatif yang diakui secara luas dengan reputasi positif.
                    Dengan misi mengutamakan kualitas, keandalan, dan keunggulan yang konsisten dalam semua produk. Serta berkomitmen hanya menggunakan bahan-bahan berkualitas dan menawarkan menunya dengan harga terbaik, serta memberikan pelayanan terbaik yang akan memuaskan konsumen.
                </>
            ),
            more: "Pelajari Selengkapnya"
        },
        image: [
            {
                image: aboutHome,
            },
            {
                image: aboutHome,
            },
            {
                image: aboutHome,
            },
            {
                image: aboutHome,
            },
            {
                image: aboutHome,
            },
        ],
        product: {
            title: "Produk Kami",
            description:
                "Kami menyediakan berbagai jenis bahan makanan bermutu tinggi dengan bahan bahan-bahan yang berkualitas.",
            more: "Lihat semua"
        },
        list: [
            {
                title: "Tepung Roti",
                image: productImage1,
                link: '/product/1'
            },
            {
                title: "MSG",
                image: productImage2,
                link: '/product/2'
            },
            {
                title: "Tepung Maizena",
                image: productImage3,
                link: '/product/3'
            },
            {
                title: "Tapioka",
                image: productImage4,
                link: '/product/4'
            },
            {
                title: "Bubuk Cabe",
                image: productImage5,
                link: '/product/5'
            },
        ],
        WhyUs: {
            title: "Mengapa harus pilih kami?",
            description: "Kami berkomitmen untuk menghasilkan produk terbaik untuk kebutuhan pangan Anda. Berikut adalah beberapa alasan mengapa Anda harus memilih produk kami.",
            list: [
                {
                    title: "Kualitas Tinggi",
                    description: 'Kami memastikan setiap produk yang kami hasilkan memenuhi standar kualitas tertinggi, menggunakan bahan-bahan pilihan dan proses produksi yang canggih untuk memastikan produk kami tidak hanya enak tapi juga bergizi.',
                    image: whyUs1,
                },
                {
                    title: "Harga Kompetitif",
                    description: 'Kami menawarkan produk dengan harga yang kompetitif tanpa mengorbankan kualitas, memberikan nilai terbaik untuk konsumen kami. Kami juga menawarkan harga yang lebih spesial untuk pembelian atau kerjasama makloon.',
                    image: whyUs2,
                },
                {
                    title: "Produk Bersertifikasi",
                    description: 'Semua produk kami telah melalui proses sertifikasi untuk memastikan keamanan dan kepatuhan terhadap regulasi.',
                    image: whyUs3,
                },
                {
                    title: "Pelayanan Pelanggan yang Baik",
                    description: 'Kami selalu siap mendengarkan dan merespon masukan serta saran dari pelanggan, untuk terus meningkatkan kualitas produk dan layanan kami.',
                    image: whyUs4,
                },
                {
                    title: "Inovasi Berkelanjutan",
                    description: 'Kami selalu berusaha untuk berinovasi dalam pengembangan produk, mengikuti tren dan kebutuhan pasar untuk menghadirkan produk-produk baru yang sesuai dengan selera konsumen.',
                    image: whyUs5,
                },
            ]
        },
        video: {
            title: "Video",
            description: "Rasakan diri Anda dalam dunia kami melalui video menawan yang menampilkan produk, proses, dan semangat di balik semua yang kami lakukan.",
            list: [
                {
                    title: "Ini baru mie kering yang enaknya gak main-main"
                },
                {
                    title: "Proses Pembuatan Mie Kering"
                },
                {
                    title: "Pengolahan Tepung"
                },
                {
                    title: "Pengolahan mie kering"
                }
            ],
            more: "Lihat semua"
        }
    },
    AboutPage: {
        hero: {
            title: "Tentang Kami",
            description:(
                <>
                    Kami adalah perusahaan manufaktur yang berdiri sejak tahun 2023, didirikan
                    dengan visi untuk menjadi pemimpin dalam industri makanan melalui penyediaan
                    berbagai produk berkualitas tinggi yang dapat memenuhi segala kebutuhan
                    pangan Anda.
                    <br/><br/>
                    Sejak awal berdirinya, kami telah berfokus pada inovasi dan
                    kualitas, memastikan bahwa setiap produk yang kami hasilkan tidak hanya lezat
                    tetapi juga sehat dan aman untuk dikonsumsi. Dengan beragam pilihan produk
                    yang kami tawarkan, kami berusaha untuk memenuhi selera dan kebutuhan dari
                    berbagai kalangan konsumen. 
                    <br/><br/>
                    Kami berkomitmen untuk menggunakan
                    bahan-bahan terbaik dan proses produksi yang canggih, sambil terus
                    beradaptasi dengan tren dan kebutuhan pasar yang terus berkembang, agar
                    selalu bisa memberikan yang terbaik bagi Anda. Sebagai perusahaan yang
                    berorientasi pada kepuasan pelanggan, kami juga selalu siap mendengarkan
                    masukan dan saran untuk terus meningkatkan kualitas dan pelayanan kami,
                    sehingga Anda selalu dapat menikmati produk makanan yang berkualitas dari
                    kami.
                </>
            )
        },
        images: {
            image_1: aboutUs,
            image_2: aboutUs,
            image_3: aboutUs,
            image_4: aboutUs,
            image_5: aboutUs,
        },
        visionMission: {
            title: "Visi & Misi",
            description: 
                'Visi kami adalah menjadi manufaktur produk inovatif yang diakui secara luas dengan reputasi positif. Dengan misi mengutamakan kualitas, keandalan, dan keunggulan yang konsisten dalam semua produk. Serta berkomitmen hanya menggunakan bahan-bahan berkualitas dan menawarkan menunya dengan harga terbaik, serta memberikan pelayanan terbaik yang akan memuaskan konsumen.'
        },
        certificate: {
            title: "Sertifikat",
            list: [
                {
                    name: 'Sertifikat Halal dari LPPOM MUI',
                    image: certificateImage1,
                },
                {
                    name: 'No MD dari BPOM RI',
                    image: certificateImage2,
                },
                {
                    name: 'ISO 22000:2005',
                    image: certificateImage3,
                },
                {
                    name: 'No MD dari BPOM RI',
                    image: certificateImage4,
                },
            ]
        },
    },
    ProductDetailPage: [
        {
            id: 1,
            hero: {
                title: "Tepung Roti",
                name: "Ringkasan",
                description:
                    "Untuk memenuhi permintaan dan kebutuhan pelanggan, PT. Pagar Utama Panganindo menyediakan berbagai jenis tepung roti. Terdapat 2 jenis tepung roti yang diproduksi oleh PT. Pagar Utama Panganindo yaitu varian tepung roti dengan warna putih dan kuning yang diharapkan akan memenuhi kebutuhan pelanggan yang beragam."
            },
            imageList: [
                {
                    image: detailImage1,
                },
                {
                    image: detailImage2,
                },
                {
                    image: detailImage3,
                },
                {
                    image: detailImage4,
                },
                {
                    image: detailImage5,
                },
            ],
            application: {
                title: "Aplikasi Penggunaan",
                description: "Berikut merupakan beberapa cara yang dapat Anda lakukan untuk mengaplikasikan tepung roti.",
                list: [
                    {
                        image: detailApplication1,
                        name: "Coating",
                        caption: "(Pelapis adonan)",
                    },
                    {
                        image: detailApplication2,
                        name: "Topping",
                        caption: "(Penutup adonan)",
                    },
                    {
                        image: detailApplication3,
                        name: "Stuffing",
                        caption: "(Adonan isi)",
                    },
                ],
            },
            productType: {
                title: "Ragam Produk",
                list: [
                    {
                        image: detailProductType1,
                        name: "Tepung Putih 200g",
                    },{
                        image: detailProductType2,
                        name: "Tepung Putih 500g",
                    },{
                        image: detailProductType3,
                        name: "Tepung Putih 1kg",
                    },{
                        image: detailProductType4,
                        name: "Tepung Putih 10kg",
                    },{
                        image: detailProductType5,
                        name: "Tepung Kuning 200g",
                    },{
                        image: detailProductType6,
                        name: "Tepung Kuning 500g",
                    },{
                        image: detailProductType7,
                        name: "Tepung Kuning 1kg",
                    },{
                        image: detailProductType8,
                        name: "Tepung Kuning 10kg",
                    },
                ],
            },
        },
        {
            id: 2,
            hero: {
                title: "MSG",
                name: "Ringkasan",
                description:
                    "Untuk memenuhi permintaan dan kebutuhan pelanggan, PT. Pagar Utama Panganindo menyediakan berbagai jenis tepung roti. Terdapat 2 jenis tepung roti yang diproduksi oleh PT. Pagar Utama Panganindo yaitu varian tepung roti dengan warna putih dan kuning yang diharapkan akan memenuhi kebutuhan pelanggan yang beragam."
            },
            imageList: [
                {
                    image: detailImage1,
                },
                {
                    image: detailImage2,
                },
                {
                    image: detailImage3,
                },
                {
                    image: detailImage4,
                },
                {
                    image: detailImage5,
                },
            ],
            application: {
                title: "Aplikasi Penggunaan",
                description: "Berikut merupakan beberapa cara yang dapat Anda lakukan untuk mengaplikasikan tepung roti.",
                list: [
                    {
                        image: detailApplication1,
                        name: "Coating",
                        caption: "(Pelapis adonan)",
                    },
                    {
                        image: detailApplication2,
                        name: "Topping",
                        caption: "(Penutup adonan)",
                    },
                    {
                        image: detailApplication3,
                        name: "Stuffing",
                        caption: "(Adonan isi)",
                    },
                ],
            },
            productType: {
                title: "Ragam Produk",
                list: [
                    {
                        image: detailProductType1,
                        name: "Tepung Putih 200g",
                    },{
                        image: detailProductType2,
                        name: "Tepung Putih 500g",
                    },{
                        image: detailProductType3,
                        name: "Tepung Putih 1kg",
                    },{
                        image: detailProductType4,
                        name: "Tepung Putih 10kg",
                    },{
                        image: detailProductType5,
                        name: "Tepung Kuning 200g",
                    },{
                        image: detailProductType6,
                        name: "Tepung Kuning 500g",
                    },{
                        image: detailProductType7,
                        name: "Tepung Kuning 1kg",
                    },{
                        image: detailProductType8,
                        name: "Tepung Kuning 10kg",
                    },
                ],
            },
        },
        {
            id: 3,
            hero: {
                title: "Tepung Maizena",
                name: "Ringkasan",
                description:
                    "Untuk memenuhi permintaan dan kebutuhan pelanggan, PT. Pagar Utama Panganindo menyediakan berbagai jenis tepung roti. Terdapat 2 jenis tepung roti yang diproduksi oleh PT. Pagar Utama Panganindo yaitu varian tepung roti dengan warna putih dan kuning yang diharapkan akan memenuhi kebutuhan pelanggan yang beragam."
            },
            imageList: [
                {
                    image: detailImage1,
                },
                {
                    image: detailImage2,
                },
                {
                    image: detailImage3,
                },
                {
                    image: detailImage4,
                },
                {
                    image: detailImage5,
                },
            ],
            application: {
                title: "Aplikasi Penggunaan",
                description: "Berikut merupakan beberapa cara yang dapat Anda lakukan untuk mengaplikasikan tepung roti.",
                list: [
                    {
                        image: detailApplication1,
                        name: "Coating",
                        caption: "(Pelapis adonan)",
                    },
                    {
                        image: detailApplication2,
                        name: "Topping",
                        caption: "(Penutup adonan)",
                    },
                    {
                        image: detailApplication3,
                        name: "Stuffing",
                        caption: "(Adonan isi)",
                    },
                ],
            },
            productType: {
                title: "Ragam Produk",
                list: [
                    {
                        image: detailProductType1,
                        name: "Tepung Putih 200g",
                    },{
                        image: detailProductType2,
                        name: "Tepung Putih 500g",
                    },{
                        image: detailProductType3,
                        name: "Tepung Putih 1kg",
                    },{
                        image: detailProductType4,
                        name: "Tepung Putih 10kg",
                    },{
                        image: detailProductType5,
                        name: "Tepung Kuning 200g",
                    },{
                        image: detailProductType6,
                        name: "Tepung Kuning 500g",
                    },{
                        image: detailProductType7,
                        name: "Tepung Kuning 1kg",
                    },{
                        image: detailProductType8,
                        name: "Tepung Kuning 10kg",
                    },
                ],
            },
        },
        {
            id: 4,
            hero: {
                title: "Tapioka",
                name: "Ringkasan",
                description:
                    "Untuk memenuhi permintaan dan kebutuhan pelanggan, PT. Pagar Utama Panganindo menyediakan berbagai jenis tepung roti. Terdapat 2 jenis tepung roti yang diproduksi oleh PT. Pagar Utama Panganindo yaitu varian tepung roti dengan warna putih dan kuning yang diharapkan akan memenuhi kebutuhan pelanggan yang beragam."
            },
            imageList: [
                {
                    image: detailImage1,
                },
                {
                    image: detailImage2,
                },
                {
                    image: detailImage3,
                },
                {
                    image: detailImage4,
                },
                {
                    image: detailImage5,
                },
            ],
            application: {
                title: "Aplikasi Penggunaan",
                description: "Berikut merupakan beberapa cara yang dapat Anda lakukan untuk mengaplikasikan tepung roti.",
                list: [
                    {
                        image: detailApplication1,
                        name: "Coating",
                        caption: "(Pelapis adonan)",
                    },
                    {
                        image: detailApplication2,
                        name: "Topping",
                        caption: "(Penutup adonan)",
                    },
                    {
                        image: detailApplication3,
                        name: "Stuffing",
                        caption: "(Adonan isi)",
                    },
                ],
            },
            productType: {
                title: "Ragam Produk",
                list: [
                    {
                        image: detailProductType1,
                        name: "Tepung Putih 200g",
                    },{
                        image: detailProductType2,
                        name: "Tepung Putih 500g",
                    },{
                        image: detailProductType3,
                        name: "Tepung Putih 1kg",
                    },{
                        image: detailProductType4,
                        name: "Tepung Putih 10kg",
                    },{
                        image: detailProductType5,
                        name: "Tepung Kuning 200g",
                    },{
                        image: detailProductType6,
                        name: "Tepung Kuning 500g",
                    },{
                        image: detailProductType7,
                        name: "Tepung Kuning 1kg",
                    },{
                        image: detailProductType8,
                        name: "Tepung Kuning 10kg",
                    },
                ],
            },
        },
        {
            id: 5,
            hero: {
                title: "Bubuk Cabe",
                name: "Ringkasan",
                description:
                    "Untuk memenuhi permintaan dan kebutuhan pelanggan, PT. Pagar Utama Panganindo menyediakan berbagai jenis tepung roti. Terdapat 2 jenis tepung roti yang diproduksi oleh PT. Pagar Utama Panganindo yaitu varian tepung roti dengan warna putih dan kuning yang diharapkan akan memenuhi kebutuhan pelanggan yang beragam."
            },
            imageList: [
                {
                    image: detailImage1,
                },
                {
                    image: detailImage2,
                },
                {
                    image: detailImage3,
                },
                {
                    image: detailImage4,
                },
                {
                    image: detailImage5,
                },
            ],
            application: {
                title: "Aplikasi Penggunaan",
                description: "Berikut merupakan beberapa cara yang dapat Anda lakukan untuk mengaplikasikan tepung roti.",
                list: [
                    {
                        image: detailApplication1,
                        name: "Coating",
                        caption: "(Pelapis adonan)",
                    },
                    {
                        image: detailApplication2,
                        name: "Topping",
                        caption: "(Penutup adonan)",
                    },
                    {
                        image: detailApplication3,
                        name: "Stuffing",
                        caption: "(Adonan isi)",
                    },
                ],
            },
            productType: {
                title: "Ragam Produk",
                list: [
                    {
                        image: detailProductType1,
                        name: "Tepung Putih 200g",
                    },{
                        image: detailProductType2,
                        name: "Tepung Putih 500g",
                    },{
                        image: detailProductType3,
                        name: "Tepung Putih 1kg",
                    },{
                        image: detailProductType4,
                        name: "Tepung Putih 10kg",
                    },{
                        image: detailProductType5,
                        name: "Tepung Kuning 200g",
                    },{
                        image: detailProductType6,
                        name: "Tepung Kuning 500g",
                    },{
                        image: detailProductType7,
                        name: "Tepung Kuning 1kg",
                    },{
                        image: detailProductType8,
                        name: "Tepung Kuning 10kg",
                    },
                ],
            },
        },
    ],
    ContactPage: {
        hero: {
            title: "Tertarik dengan produk kami?",
            description:
                "Ingin melakukan pemesanan? Menjalin kerja sama? Atau sekadar memiliki pertanyaan mengenai produk kami? Isi form dibawah ini dan salah satu anggota kami akan segera menghubungi Anda."
        },
        form: {
            name: "Nama",
            phone: "Whatsapp/Nomor Telepon",
            email: "Email",
            message: "Keperluan Anda",
            send: "Kirim"
        }
    },
    footer: {
        location: `Alamat Headquarter`,
        locationDescription: `PT. Pagar Utama Panganindo, Ruko Duta Gardenia Blok D/28, Jurumudi Baru, Tangerang, Banten, 15124`,
        shortcutLink: "Pintasan Link",
        email: `Email`,
        emailDescription: `marcom@smwfood.com`,
        callCenter: "Call Center",
        callCenterDescription: `+62 852-8372-8394`
    }
}
