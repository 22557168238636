import { Icon } from "@iconify/react";
import { useState } from "react";
import { Col, Container, Image, Modal, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from '../../assets/logo_hitam_putih.png';
import Palette from "../utils/Palette";

export default function Footer({isTablet, isMobile, lang, setLang}) {
    const navigate = useNavigate()
    const location = useLocation()
    const [showDialog, setShowDialog] = useState(false)
    const [openIndex, setOpenIndex] = useState(null);

    const toggleDropdown = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const arrowIconStyle = {
        transition: 'transform 0.3s',
        color: Palette.THEME_WHITE,
    };
    
    return (
        <>
            <Modal onHide={() => {
                setShowDialog(false)
            }} show={showDialog}>
            </Modal>
            <div className={"d-flex flex-row align-items-center justify-content-center"} style={{
                color: "white",
                background: "#0A0A0A",
                width: "100vw",
            }}>
                <Container style={{padding: isMobile ? '48px 16x' : isTablet ? '48px 16px' : '80px 0px',}}>
                    <Row style={{ marginBottom: '36px' }}>
                        <Col className={"d-flex flex-row align-items-start justify-content-start "}
                            md={5}>
                            <Link className="text-white text-decoration-none" to={'/'}>
                                <div className="d-flex">
                                    <Image style={{width: 200, marginRight: 10}} src={logo}></Image>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '36px', gap: '36px' }}>
                        <Col
                            className={"flex-row align-items-start justify-content-start "}
                            md={5}>
                            <div className={"d-flex flex-row"} style={{ gap: '4px' }}>
                                <div className={"d-flex flex-column"}>
                                    <Link className="text-white">
                                        <Icon icon="mdi:location"/>
                                    </Link>
                                </div>
                                <div className={"d-flex flex-column"} style={{ fontSize: isMobile || isTablet ? "18px" : "20px", fontWeight: "bold" }}>
                                    {lang.footer.location}
                                </div>
                            </div>
                            <div className={"d-flex flex-row"} style={{ marginBottom: '36px', fontSize: isMobile || isTablet ? "16px" : "18px", fontWeight: 400 }}>
                                {lang.footer.locationDescription}
                            </div>

                            <div className={"d-flex flex-row"} style={{ gap: '4px' }}>
                                <div className={"d-flex flex-column"}>
                                    <Link className="text-white">
                                        <Icon icon="ic:round-email" width="20" height="20" />
                                    </Link>
                                </div>
                                <div className={"d-flex flex-column"} style={{ fontSize: isMobile || isTablet ? "18px" : "20px", fontWeight: "bold" }}>
                                    {lang.footer.email}
                                </div>
                            </div>
                            <div className={"d-flex flex-row"} style={{ marginBottom: '36px', fontSize: isMobile || isTablet ? "16px" : "18px", fontWeight: 400 }}>
                                {lang.footer.emailDescription}
                            </div>

                            <div className={"d-flex flex-row"} style={{ gap: '4px' }}>
                                <div className={"d-flex flex-column"}>
                                    <Link className="text-white">
                                        <Icon icon="ic:round-phone" width="18" height="18" />
                                    </Link>
                                </div>
                                <div className={"d-flex flex-column"} style={{ fontSize: isMobile || isTablet ? "18px" : "20px", fontWeight: "bold" }}>
                                    {lang.footer.callCenter}
                                </div>
                            </div>
                            <div className={"d-flex flex-row"} style={{ marginBottom: '36px', fontSize: isMobile || isTablet ? "16px" : "18px", fontWeight: 400 }}>
                                {lang.footer.callCenterDescription}
                            </div>
                        </Col>
                        <Col style={{marginLeft: 'auto', marginRight: 'auto'}}
                            className={"d-flex flex-column"} md={5}>
                            <Row>
                                <h4 style={{fontWeight: 'bold', fontSize: isMobile || isTablet ? "20px" : '28px', fontFamily: 'Bitter'}} className={"mb-4"}>{lang.footer.shortcutLink}</h4>
                            </Row>
                            <Row
                                className={"mt-1 mb-1"}
                                style={{
                                    color: Palette.THEME_WHITE
                                }}
                            >
                                {lang.MENU_FOOTER?.map((obj, index) => (
                                    <Col className="mb-2 mt-2 ml-auto" sm={12} md={12} key={index}>
                                        <div style={{ marginBottom: '4px' }}>
                                            <div
                                            // onClick={() => obj.path}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <span onClick={() => {
                                                        if (index === 1) {
                                                            toggleDropdown(index)
                                                        } else {
                                                            navigate(obj.path); 
                                                            scrollToTop()
                                                        }
                                                    }} 
                                                    style={{ fontWeight: 600, color: Palette.THEME_WHITE, flex: 1 }}
                                                >
                                                    {obj.name}
                                                </span>
                                                {obj.submenu && obj.submenu.length > 0 && (
                                                    <Icon
                                                        onClick={() => toggleDropdown(index)}
                                                        icon="simple-line-icons:arrow-down"
                                                        width="10"
                                                        height="10"
                                                        style={{ 
                                                            ...arrowIconStyle,
                                                            transform: openIndex === index ? 'rotate(180deg)' : 'rotate(0deg)',
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            {obj.submenu && obj.submenu.length > 0 && openIndex === index && (
                                            <div style={{ marginLeft: '16px' }}>
                                                {obj.submenu.map((subItem, subIndex) => (
                                                <div key={subIndex} style={{ fontWeight: 600, marginTop: '10px' }}>
                                                    <Link
                                                        style={{
                                                            textDecoration: 'none',
                                                            color: Palette.THEME_WHITE,
                                                        }}
                                                        to={subItem.path}
                                                    >
                                                        {subItem.name}
                                                    </Link>
                                                </div>
                                                ))}
                                            </div>
                                            )}
                                        </div>
                                    </Col>
                                ))}
                            </Row>

                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '-20px' }} md={12}>
                        <Col className={"d-flex flex-column " + isMobile || isTablet ? "mb-4" : ""}>
                            <div 
                                style={{
                                    fontWeight: 500,
                                    fontSize: isMobile || isTablet ? '14px' : '16px',
                                    color: Palette.THEME_WHITE
                                }} 
                                className="d-flex flex-row justify-content-between align-items-center mb-4"
                            >
                                {/* Copyright {new Date().getFullYear()}. All Rights Reserved */}
                                Copyright 2023. All Rights Reserved
                                <div className="ml-auto">
                                    <Icon
                                        icon="ph:arrow-circle-up-thin"
                                        width="40"
                                        height="40"
                                        onClick={scrollToTop}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )


}